<script setup lang="ts">
import { ref } from 'vue';

defineProps({
  listing: {
    type: Object
  }
})
const isOpen = ref(false);

const appId = useRuntimeConfig().public.appId;

</script>

<template>
  <w-button
    class="flex items-center px-3 w-1/2 justify-center text-center border border-white py-2.5 rounded-lg bg-indigo-200 text-indigo-800 btn-w-half"
    @click="isOpen = true"><i class="fa-solid fa-envelope me-2"></i> Email</w-button>

  <w-modal v-model="isOpen" :transition="false" class="w-full" :ui="{ container: 'items-center' }">
    <div class="p-3 text-center flex flex-col gap-3">
      <h3 class="text-gray-900 text-2xl my-3">Send a request</h3>
      <img :src="`images/${appId}/logo-blue.webp`" alt="" class="my-2 w-auto h-8 object-contain">
      <layout-email-form :listing="listing"></layout-email-form>
    </div>
  </w-modal>
</template>