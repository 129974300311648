<script setup>
import { useSettingsStore } from '~/stores/settings.store';
import { useMetaStore } from '~/stores/meta.store';

const props = defineProps({
  data: { type: Object, required: true },
  meta: { type: Object, required: true }
})

const settingStore = useSettingsStore()
const whatsapp = settingStore.settings.social_media_whatsapp;

const metaStore = useMetaStore();
const meta = ref({});
metaStore.getMetaTags()

meta.value = metaStore.metaGetter();
metaStore.setMeta({ ...props.meta });
</script>

<template>
  <LayoutAppHead></LayoutAppHead>
  <NuxtLayout name="mobile-base">
    <template #mobile-header>
      <mobile-header :title="$t('client.details')">
        <template #header-left>
          <mobile-back-button></mobile-back-button>
        </template>
      </mobile-header>
    </template>

    <div class="px-4">
      <div class="my-3 p-3">
        <div class="flex flex-col items-center justify-center text-center gap-3">
          <img :src="data.picture" alt="" class="rounded-full w-40 h-40 object-cover">
          <div class="flex flex-col">
            <h3 class="text-black text-2xl font-medium">{{ data.name }}</h3>
            <p class="opacity-80 text-center text-black text-base font-normal mt-1">{{ data.position }}</p>
            <span class="opacity-40 text-center text-black mt-1" v-for="lang in data.supported_languages">
              {{ lang.name }}
            </span>
            <div class="text-black text-base font-normal mt-3 text-left" v-html="data.description"></div>
          </div>
        </div>
      </div>
    </div>

    <template #mobile-footer>
     <div class="relative z-[1000]">
        <div class="fixed bottom-0 bg-white p-4 w-full">
          <div class="ms-auto flex items-center gap-4">
            <mobile-modal-contact></mobile-modal-contact>
            <a :href="whatsapp" target="_blank"
              class="flex items-center px-3 w-1/2 justify-center border border-white py-2.5 rounded-lg bg-[#daebe2] text-[#28b06e]">
              <i class="fa-brands fa-whatsapp fa-lg me-2"></i> Whatsapp
            </a>
          </div>
        </div>
     </div>
    </template>
  </NuxtLayout>
</template>